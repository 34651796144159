<template>
  <div class="page-root">
    <answer-box
      @next="onNext"
      @back="onBack"
      :id="questions[number].id"
      :count="questions.length"
      :answer-text="questions[number].text"
      :choices="questions[number].choices"
      color="green"
    />

    <ProgressModal :is-modal-active="isConfirmUserInfo" />
  </div>
</template>

<script>
import rootStore from "@/store";
import { partAQuestions } from "../../data/adhd";
import AnswerBox from "@/components/adhd/AnswerBox";
import ProgressModal from "@/components/adhd/ProgressModal";
export default {
  name: "AdhdFirstScreening",
  head: {
    title: {
      inner: "大人のADHDセルフチェック"
    }
  },
  data: function() {
    return {
      questions: partAQuestions(),
      number: 0,
      isConfirmUserInfo: true
    };
  },
  components: { AnswerBox, ProgressModal },
  methods: {
    async onNext(idx) {
      this.$store.commit("adhd/changeScreeningAAnswerValue", {
        id: this.questions[this.number].id,
        value: idx
      });

      if (this.number === this.questions.length - 1) {
        await this.$router.push("/adhd/first_result");
      } else {
        this.number += 1;
      }
    },
    onBack() {
      this.number -= 1;
    }
  },
  beforeCreate: function() {
    rootStore.commit("adhd/resetAllAnswer");
    const url = new URL(
      "v2/api/self_checker/user_info",
      process.env.VUE_APP_API_BASE_URL
    ).toString();
    this.axios
      .get(url, {
        withCredentials: true
      })
      .then(res => {
        this.$cookies.set("patient_id", res.data.patientId, "24h");
        this.$store.commit("adhd/changeUserInfo", {
          patientId: res.data.patientId,
          userStatus: res.data.userStatus
        });
        this.isConfirmUserInfo = false;
      })
      .catch(() => {
        this.$router.push("/adhd/error");
      });
  },
  created() {
    this.sendGa("click", "adhd", "start-first-screening");
  }
};
</script>
<style scope>
@import "../../assets/style/adhd.css";
</style>
